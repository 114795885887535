
import { defineComponent } from 'vue'
import { getProjectPeople } from '@/api/project'
import { exportProjectWorkers } from '@/api/export'
import { ProjectDto } from '@/api/dto'
export default defineComponent({
    name: 'ProjectMake',
    data () {
        return {
            tableData: [] as ProjectDto[],
            pageNum: 1,
            pageSize: 10,
            total: 0,
            searchText: '',
            searchValue: '',
            selects: [] as string[],
            serveCompanyName: '',
            projectName: '',
            createTime: '',
            serveCompanyId: 0 as number | string
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        async handleCurrentChange () {
            const result = await getProjectPeople({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                searchText: this.searchValue,
                projectId: Number(this.$route.params.id)
            })
            this.tableData = result.list
            this.total = result.totalCount
            this.serveCompanyName = result.serveCompanyName
            this.projectName = result.projectName
            this.createTime = result.createTime
            this.serveCompanyId = result.serveCompanyId
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.handleCurrentChange()
        },
        onReset () {
            this.searchText = ''
            this.search()
        },
        async onExport () {
            const result = await exportProjectWorkers({
                ids: this.$route.params.id as string,
                serveCompanyId: this.serveCompanyId
            })
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    result

            this.$message.success('导出成功！')
        },
        handleSelectionChange (selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        }
    }
})
